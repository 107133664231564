import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { IMFICLDeliveryScheduleParameters } from '../actions/CoreProductDataServiceEntities.g';
import getDeliveryMessageAction, { GetDeliveryMessageInput } from '../actions/get-delivery-message.action';

const _getDeliveryByMessage = async (request: IMFICLDeliveryScheduleParameters, context: ICoreContext<IGeneric<IAny>>, isCached?: boolean) => {
    if (request) {
        const getDeliveryMessageActionInput = new GetDeliveryMessageInput(request, isCached);
        try {
            let result;
            // eslint-disable-next-line prefer-const
            result = await getDeliveryMessageAction(getDeliveryMessageActionInput, context.actionContext);
            if (result) {
                return result;
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return null;
};

export default _getDeliveryByMessage;
